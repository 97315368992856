<script>
export default {
  props: {
    coverImage: String,
    coverCenterImage: String,
    logo: String,
    name: String,
    subtitle: String,
    buttonText: String,
    date: String,
    screenshot: String,
    website: String,
    mobile: {
      title: String,
      description: String,
      image: String,
    },
    path: String,
    mission: String,
    briefLink: String,
    devicesImage: String,
    typo: {
      description: String,
      font: String,
    },
    colors: {
      description: String,
      colorCodes: Array,
    },
    screenshotFull: String,
    presentation: {
      bgImage: String,
      fgImage: String,
    },
  },
}
</script>

<template>
  <div class="comp-cont">
    <div class="bg-img">
      <img :src="require(`@assets/images/${presentation.bgImage}`)" />
    </div>
    <div class="fg-elts">
      <div class="fg-img">
        <img :src="require(`@assets/images/${presentation.fgImage}`)" />
      </div>
      <div class="text-left">
        <h4 class="title-article"
          ><a :href="website" class="link-underline">{{ name }} </a></h4
        >
        <h5>{{ subtitle }}</h5>
        <a :href="'/projets/' + path" class="btn btn--smaller mt-5">
          <span class="circle bg-color-green"> </span>
          <span class="button-text">{{
            buttonText ? buttonText : 'En savoir +'
          }}</span>
        </a>
        <!--  <router-link to="" class="btn btn--smaller mt-5">
              <span class="circle bg-color-green "> </span>
              <span class="button-text">{{ buttonText ? buttonText : 'En savoir +' }}</span>
          </router-link>-->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.comp-cont {
  position: relative;
  height: 570px;
  margin-bottom: 30px;
  &:hover {
    .bg-img {
      img {
        transform: scale(1.05);
      }
    }
  }

  @include respond-below(lg) {
    height: 500px;
  }

  @include respond-below(xs) {
    height: 460px;
  }
  .bg-img {
    position: absolute;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: black;

    img {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      transform: scale(1);
      object-fit: cover;
    }
  }
  .fg-elts {
    position: absolute;
    bottom: 5%;
    width: 100%;
    .fg-img {
      width: 60%;
      margin-bottom: 30px;
      text-align: center;
      img {
        max-height: 150px;

        @include respond-below(xs) {
          max-height: 110px;
        }
      }
    }
  }
}
</style>
