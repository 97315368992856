import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const projectsComputed = {
  ...mapState('projects', ['projects', 'projectsFiltered']),
}

export const partnersComputed = {
  ...mapState('partners', ['partners', 'partnersFiltered']),
}

export const projetDoneComputed = {
  ...mapState('projetDone', ['projetDone', 'projetDoneFiltered']),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut'])
