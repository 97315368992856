<script>
import Layout from '@layouts/main.vue'
import ArticleComponent from '@components/article-component.vue'
import { projectsComputed } from '@state/helpers'

export default {
  page: {
    title: 'Les réalisations de Xenius Consulting - Agence de communication',
    meta: [
      {
        name: 'description',
        content:
          'Création site internet, création graphique, stratégie de communication digitale… Découvrez les réalisations de notre agence de communication',
      },
    ],
  },
  components: { Layout, ArticleComponent },
  computed: {
    ...projectsComputed,
  },
}
</script>

<template>
  <Layout>
    <section>
      <div
        :class="$style.headerBg"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 100%), url(' +
            require('@assets/images/travail-bg.png') +
            ')',
        }"
      ></div>
      <div class="container">
        <div :class="$style.paddingTop">
          <div class="row mb-5">
            <div class="col col-lg-7 col-md-7">
              <h1
                :class="$style.pageTitle"
                class="font-weight-bolder title-section-desc"
                >Nos Réalisations</h1
              >
              <h2 class="font-weight-bolder title-section"
                >RELEVER LES DEFIS DE VOTRE TRANSFORMATION DIGITALE</h2
              >
              <p class="text-justify"
                >Nos clients sont uniques, leurs satisfaction est notre
                priorité.</p
              >
              <p class="text-justify"
                >Nous étudions vos besoins et nous vous offrons un
                accompagnement personnalisé
                <strong
                  >pour apporter une solution globale de communication à votre
                  image.</strong
                ></p
              >
              <p class="text-justify"
                >Nous mettons notre expertise à votre service pour vous aider à
                adapter vos outils digitaux adéquats à votre métier.</p
              >
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <ArticleComponent v-bind="projects[0]" />
            </div>
            <div class="col-md-6">
              <div class="mt-5"></div>
              <ArticleComponent v-bind="projects[1]" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <ArticleComponent v-bind="projects[2]" />
            </div>
            <div class="col-md-6">
              <div class="mt-5"></div>

              <ArticleComponent v-bind="projects[3]" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <ArticleComponent v-bind="projects[4]" />
            </div>
            <div class="col-md-6">
              <div class="mt-5"></div>
              <ArticleComponent v-bind="projects[5]" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <ArticleComponent v-bind="projects[6]" />
            </div>
            <div class="col-md-6">
              <div class="mt-5"></div>
              <ArticleComponent v-bind="projects[7]" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <ArticleComponent v-bind="projects[8]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';
.headerBg {
  position: absolute;
  width: 100%;
  height: 75vh;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.pageTitle {
  color: $color-theme-green;
}
.paddingTop {
  padding-top: 25vh;
}
</style>
